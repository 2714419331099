import React from "react"
import HelpOverlay from "mill/components/HelpOverlay"
import ProgressBar from "mill/components/ProgressBar"
import styled from "styled-components"

const Card = styled.div`
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-top: 1.5rem;
  text-align: left;
  color: #909090;
  position: relative;
  text-align: center;

  img {
    max-height: 150px;
  }
`

const Inner = styled.div`
  padding: 2rem;
`

const BadgeCard = props => {
  const baseURL = props.imageUrl
  const resizedBadgeURL = `${baseURL}?auto=format,compress&h=200`
  const badgeURL = props.awarded
    ? resizedBadgeURL
    : `${resizedBadgeURL}&sat=-100`

  return (
    <Card unawarded={!props.awarded}>
      <Inner>
        {props.tooltip && <HelpOverlay helpText={props.tooltip} />}
        <img src={badgeURL} className="fit" />
      </Inner>
      <ProgressBar
        fillColor={!props.awarded && `#ACACAC`}
        percentage={props.progress}
      />
    </Card>
  )
}

export default BadgeCard
