import React from "react"
import Card from "mill/components/Card"
import StatisticCircle from "mill/components/StatisticCircle"
import styled, { withTheme } from "styled-components"

const Container = styled.div`
  flex: 1;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;

  & > div {
    flex: 1;
  }
`

const AnswerAccuracy = props => {
  const { loaded, firstTryAccuracy, secondTryAccuracy } = props

  return (
    <Card
      flex
      title="Answer Accuracy"
      height={372}
      tooltip={
        "These graphs represent the accuracy of your first and second attempts at all questions you’ve answered."
      }
      tooltipPosition={"left"}>
      <Container>
        <StatisticCircle
          loaded={loaded}
          percentage={firstTryAccuracy}
          labelAlign={"right"}
          color={props.theme.colors.primary}
          label={"Correct on first attempt of all questions"}
        />

        <StatisticCircle
          loaded={loaded}
          percentage={secondTryAccuracy}
          color={props.theme.colors.secondary}
          label={"Correct on second attempt of all questions"}
        />
      </Container>
    </Card>
  )
}

export default withTheme(AnswerAccuracy)
