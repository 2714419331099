import { sm, md } from "../../utils/breakpoints"
import { darken } from "polished"
import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const Container = styled.div`
  position: relative;
`

export const Track = styled.div`
  position: relative;
  display: flex;
  left: -100%;
  transition: ${props =>
    props.isSet && `transform .5s cubic-bezier(0.23, 1, 0.32, 1)`};
  transform: ${props =>
    props.isSet
      ? `none`
      : props.reversing
      ? `translateX(-100%)`
      : `translateX(100%)`};

  @media ${sm} {
    left: -50%;
    transform: ${props =>
      props.isSet
        ? `none`
        : props.reversing
        ? `translateX(-50%)`
        : `translateX(50%)`};
  }

  @media ${md} {
    left: 0;
    transform: none;
  }
`

export const Inner = styled.div`
  padding: 0 1rem;
`

export const CarouselItem = styled.div`
  flex: 1 0 100%;

  order: ${props => props.order};

  @media ${sm} {
    flex-basis: 50%;
  }

  @media ${md} {
    order: 2;
    flex-basis: 20%;
  }
`

export const ControlButton = styled.div`
  position: absolute;
  color: ${props => props.theme.colors.primary};
  font-size: 3rem;
  top: 50%;
  ${props => props.float}: 1rem;
  z-index: ${zIndex.base + 1};
  margin-top: -1.5rem;

  &:hover {
    cursor: pointer;
    color: ${props => darken(0.1, props.theme.colors.primary)};
  }

  @media ${md} {
    display: none;
  }
`
