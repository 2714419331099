import React from "react"
import { List } from "immutable"
import BadgeCard from "mill/components/BadgeCard"
import { Container, Track, CarouselItem, Inner, ControlButton } from "./styles"

class BadgeCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.prevBadge = this.prevBadge.bind(this)
    this.nextBadge = this.nextBadge.bind(this)
    this.shiftFromOriginal = this.shiftFromOriginal.bind(this)
    this.order = this.order.bind(this)
    this.state = {
      reversing: false,
      isSet: true,
      reference: 5
    }
  }

  shiftFromOriginal() {
    const offset = this.state.reference - 1
    const originalRange = List([1, 2, 3, 4, 5])
    const unshifted = originalRange.slice(offset, 5)
    const shifted = originalRange.slice(0, offset)
    return unshifted.concat(shifted).toJS()
  }

  order(item) {
    return this.shiftFromOriginal().indexOf(item) + 1
  }

  prevBadge() {
    const { reference } = this.state
    const newRef = reference - 1 < 1 ? 5 : reference - 1
    this.setState({
      reference: newRef,
      reversing: true,
      isSet: false
    })
    setTimeout(() => this.setState({ isSet: true }), 50)
  }

  nextBadge() {
    const { reference } = this.state
    const newRef = reference + 1 > 5 ? 1 : reference + 1
    this.setState({
      reference: newRef,
      reversing: false,
      isSet: false
    })
    setTimeout(() => this.setState({ isSet: true }), 50)
  }

  render() {
    const { badges } = this.props
    const rocketeer = badges.find(el => {
      return el.key == "rocketeer"
    })
    const consecutive5 = badges.find(el => {
      return el.key == "consecutive-5"
    })
    const consecutive10 = badges.find(el => {
      return el.key == "consecutive-10"
    })
    const consecutive20 = badges.find(el => {
      return el.key == "consecutive-20"
    })
    const consecutive30 = badges.find(el => {
      return el.key == "consecutive-30"
    })
    return (
      <Container>
        <ControlButton onClick={this.nextBadge} float={"right"}>
          <i className="fa fa-chevron-right" />
        </ControlButton>
        <ControlButton onClick={this.prevBadge} float={"left"}>
          <i className="fa fa-chevron-left" />
        </ControlButton>

        <Track
          itemCount={4}
          isSet={this.state.isSet}
          reversing={this.state.reversing}>
          <CarouselItem order={this.order(1)}>
            <Inner>
              <BadgeCard
                type={consecutive5.key}
                imageUrl={consecutive5.imageUrl}
                awarded={consecutive5.awarded}
                progress={consecutive5.progress}
                tooltip="Earned when you answer 5 questions correctly in a row."
                tooltipPosition={"left"}
                loaded={true}
              />
            </Inner>
          </CarouselItem>
          <CarouselItem order={this.order(2)}>
            <Inner>
              <BadgeCard
                type={consecutive10.key}
                imageUrl={consecutive10.imageUrl}
                awarded={consecutive10.awarded}
                progress={consecutive10.progress}
                tooltip="Earned when you answer 10 questions correctly in a row."
                tooltipPosition={"left"}
                loaded={true}
              />
            </Inner>
          </CarouselItem>
          <CarouselItem order={this.order(3)}>
            <Inner>
              <BadgeCard
                type={consecutive20.key}
                imageUrl={consecutive20.imageUrl}
                awarded={consecutive20.awarded}
                progress={consecutive20.progress}
                tooltip="Earned when you answer 20 questions correctly in a row."
                tooltipPosition={"left"}
                loaded={true}
              />
            </Inner>
          </CarouselItem>
          <CarouselItem order={this.order(4)}>
            <Inner>
              <BadgeCard
                type={consecutive30.key}
                imageUrl={consecutive30.imageUrl}
                awarded={consecutive30.awarded}
                progress={consecutive30.progress}
                tooltip="Earned when you answer 30 questions correctly in a row."
                tooltipPosition={"left"}
                loaded={true}
              />
            </Inner>
          </CarouselItem>
          <CarouselItem order={this.order(5)}>
            <Inner>
              <BadgeCard
                type={rocketeer.key}
                imageUrl={rocketeer.imageUrl}
                awarded={rocketeer.awarded}
                progress={rocketeer.progress}
                tooltip={`The ${rocketeer.name} badge is awarded when both the campaign duration and your overall performance reach 80%. Whoosh!`}
                tooltipPosition={"left"}
                loaded={true}
              />
            </Inner>
          </CarouselItem>
        </Track>
      </Container>
    )
  }
}

export default BadgeCarousel
